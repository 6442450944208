<template>
    <div class="flex flex-col justify-center items-center bg-white p-8 w-1/2">
      <h2 class="text-2xl font-semibold mb-6 text-gray-600">Reset Password</h2>
      <form @submit.prevent="resetPassword()" class="w-full max-w-sm space-y-8">
        <div>
          <input
            v-model="user.username"
            type="email"
            id="emailAddress"
            placeholder="Email"
            required
            class="w-full p-3 border border-gray-300 rounded focus:outline-none focus:ring focus:ring-[#1760b2]"
          />
        </div>
        <button
          type="submit"
          class="w-full bg-[#1760b2] text-white py-3 rounded hover:bg-[#1760b2]"
        >
          Reset Password
        </button>
      </form>
      <router-link to="/" class="center text-[#1760b2] mt-3 hover:underline">Back to login page</router-link>
    </div>
  </template>
  
<script setup>
import { useWerisStore } from "@/stores";
import { useRouter } from "vue-router";
import { ref, watch,onMounted } from "vue";
import authService from "@/services/authService.js";

const user = ref({
  username: "",
});

const store = useWerisStore();
const router = useRouter();

const resetPassword = () => {
  store.forgotPassword(user.value.username)
};
  </script>

  <style scoped>
  </style>
  