<template>
    <div class="flex flex-col justify-center items-center bg-white p-8 w-1/2">
      <h2 class="text-2xl font-semibold mb-6 text-gray-600">Welcome Back</h2>
      <form @submit.prevent="login()" class="w-full max-w-sm space-y-4">
        <div>
          <input
            v-model="user.username"
            type="email"
            id="emailAddress"
            placeholder="Username / Email"
            required
            class="w-full p-3 border border-gray-300 rounded focus:outline-none focus:ring focus:ring-[#1760b2]"
          />
        </div>
        <div>
          <input
            v-model="user.password"
            type="password"
            placeholder="Password"
            required
            id="password"
            class="w-full p-3 border border-gray-300 rounded focus:outline-none focus:ring focus:ring-[#1760b2]"
          />
        </div>
        <button
          type="submit"
          class="w-full bg-[#1760b2] text-white py-3 rounded hover:bg-[#1760b2]"
        >
          Log In
        </button>
      </form>
      <router-link to="forgot-password" class="center text-[#1760b2] mt-3 hover:underline">Forgot password?</router-link>
      <hr class="bg-gray-600" style="color:black">
      <div class="w-full max-w-sm">
        <div class="flex items-center gap-2 my-4">
          <hr class="bg-black w-full">
          <p class="text-gray-600 text-center">OR</p>
          <hr class="bg-black w-full">
        </div>
        <button @click="logout()" v-if="sso_user">Logout</button>
        <button type="submit" class="w-full cursor-pointer rounded-lg border border-primary text-gray-600 p-2 font-medium transition hover:bg-[#1760b2] hover:text-white flex gap-5 items-center" @click="sso_login()"> <img src="../assets/images/ubunifulogo.svg" class="w-[100px]" alt=""> Continue With Ubunifu</button>
      </div>
    </div>
  </template>
  
<script setup>
import { useWerisStore } from "@/stores";
import { useRouter } from "vue-router";
import { ref, watch,onMounted } from "vue";
import authService from "@/services/authService.js";

const user = ref({
  username: "",
  password: "",
});

const store = useWerisStore();
const router = useRouter();

const login = () => {
  store.login(user.value);
};
// Define user as a reactive variable
const sso_user = ref(null);

// Function to handle login
const sso_login = () => {
  authService.login();
};

// Function to handle logout
const logout = () => {
  authService.logout();
};

// Lifecycle hook to fetch the authenticated user on component mount
onMounted(() => {
  authService.getUser().then((authUser) => {
    if (authUser) {
      user.value = authUser; // Update the reactive variable with the user data
    }
  });
});
  </script>

  <style scoped>
  </style>
  